import React from 'react';
import { classes } from './WidgetRoot.st.css';
import { GroupsWidgetWithStyles } from '../../../Groups/Widget/App/types';
import { GroupsAppProvider } from '../../../Groups/contexts/GroupsAppProvider';
import { GroupsSection } from '../../../Groups/Widget/Groups/GroupsSection';
import { useExperiments } from '@wix/yoshi-flow-editor';
import { ThemeProvider } from '../../../../common/context/theme';

// TODO: refactor for GroupWidgetWithStyles interface
export const WidgetRoot: React.FunctionComponent<GroupsWidgetWithStyles> = (
  props: GroupsWidgetWithStyles,
) => {
  const { experiments } = useExperiments();
  return (
    <div className={classes.root}>
      <GroupsAppProvider {...props}>
        <ThemeProvider
          value={{
            forceBlackAndWhite: experiments.enabled(
              'specs.groups.ForceBlackAndWhite',
            ),
          }}
        >
          <GroupsSection />
        </ThemeProvider>
      </GroupsAppProvider>
    </div>
  );
};

WidgetRoot.displayName = 'WidgetRoot';
