import { useBi } from '@wix/yoshi-flow-editor';
import { joinRestrictedGroup } from '@wix/bi-logger-groups/dist/src/v2';
import { BIUserEntry } from 'common/bi-logger/types';
import { useUser } from 'common/context/user/useUser';
import { joinRestrictedGroupParams } from '@wix/bi-logger-groups/dist/src/v2/types';

export function usePaidPlansBi() {
  const bi = useBi();
  const { changeMembershipOrigin } = useUser();
  const defaults: joinRestrictedGroupParams = {
    type: 'paid_plans',
    userEntry: BIUserEntry.SITE,
  }

  return {
    getPlanClick() {
      bi.report(joinRestrictedGroup({
        ...defaults,
        origin: changeMembershipOrigin
      }));
    },
    getAnotherPlanClick() {
      bi.report(joinRestrictedGroup({
        ...defaults,
        origin: 'get_another_plan'
      }));
    }
  }
}
