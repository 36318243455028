import { IFedOpsLogger } from '@wix/native-components-infra/dist/src/types/types';
import { ControllerParams } from '@wix/yoshi-flow-editor';

export class FedopsLogger {
  static fromControllerConfig(
    controllerConfig: ControllerParams['controllerConfig'],
  ): IFedOpsLogger {
    const fedopsLogger = controllerConfig.platformAPIs.fedOpsLoggerFactory;
    const appId = controllerConfig.appParams.appDefinitionId;
    const widgetId = controllerConfig.type;
    return fedopsLogger!.getLoggerForWidget({ appId, widgetId });
  }
}
