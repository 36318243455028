import React from 'react';
import { TPAComponentsConsumer } from 'wix-ui-tpa/TPAComponentsConfig';
import { Text, TYPOGRAPHY } from 'wix-ui-tpa/Text';

import { st, classes } from './Modal.st.css';
import { TPAModal, TPAModalProps } from './TPAModal';
import { Theme, withTheme } from '../../context/theme';

type IModal<P> = React.FunctionComponent<P> & {
  Buttons: React.FC;
  Header: React.FC<ModalHeaderProps>;
};

export enum ModalSkin {
  REACTIONS = 'reactions',
  NEW_POST = 'newPost',
}

interface ModalHeaderProps {
  title: string;
  subtitle?: string;
}

export interface ModalProps extends TPAModalProps {
  className?: string;
  skin?: ModalSkin;
}

const ModalComponent: React.FC<ModalProps & Theme> = (props) => (
  <TPAComponentsConsumer>
    {({ mobile }) => {
      const { skin, inFullScreen, forceBlackAndWhite, ...rest } = props;
      return (
        <TPAModal
          inFullScreen={inFullScreen || mobile}
          {...rest}
          className={st(
            classes.root,
            { skin, mobile, bw: !!forceBlackAndWhite } as any,
            rest.className,
          )}
        />
      );
    }}
  </TPAComponentsConsumer>
);
export const Modal = withTheme(ModalComponent) as IModal<ModalProps>;
Modal.displayName = 'Modal';
Modal.Buttons = (props) => (
  <div className={classes.buttons}>{props.children}</div>
);

Modal.Header = ({ title, subtitle }) => (
  <div className={classes.header}>
    <Text
      typography={TYPOGRAPHY.smallTitle}
      className={classes.title}
      tagName="h1"
    >
      {title}
    </Text>
    {subtitle ? (
      <Text
        typography={TYPOGRAPHY.runningText}
        className={classes.subtitle}
        tagName="h2"
      >
        {subtitle}
      </Text>
    ) : null}
  </div>
);
